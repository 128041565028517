.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.item-select-disabled{
  background-color:transparent !important;
  opacity: 70%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-Regular.ttf")
    format("truetype");
  font-weight: 400; /* Regular weight */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-Bold.ttf")
    format("truetype");
  font-weight: 700; /* Bold weight */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-BoldItalic.ttf")
    format("truetype");
  font-weight: 700; /* Bold Italic weight */
  font-style: italic; /* Italic style */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-ExtraLight.ttf")
    format("truetype");
  font-weight: 200; /* Extra Light weight */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-ExtraLightItalic.ttf")
    format("truetype");
  font-weight: 200; /* Extra Light Italic weight */
  font-style: italic; /* Italic style */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-Italic.ttf")
    format("truetype");
  font-weight: 400; /* Regular weight Italic */
  font-style: italic; /* Italic style */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-Light.ttf")
    format("truetype");
  font-weight: 300; /* Light weight */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-LightItalic.ttf")
    format("truetype");
  font-weight: 300; /* Light Italic weight */
  font-style: italic; /* Italic style */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-Medium.ttf")
    format("truetype");
  font-weight: 500; /* Medium weight */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-MediumItalic.ttf")
    format("truetype");
  font-weight: 500; /* Medium Italic weight */
  font-style: italic; /* Italic style */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-SemiBold.ttf")
    format("truetype");
  font-weight: 600; /* Semi-Bold weight */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-SemiBoldItalic.ttf")
    format("truetype");
  font-weight: 600; /* Semi-Bold Italic weight */
  font-style: italic; /* Italic style */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-Thin.ttf")
    format("truetype");
  font-weight: 100; /* Thin weight */
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./tenants/kolula/assets/createFont/IBMPlexSans-ThinItalic.ttf")
    format("truetype");
  font-weight: 100; /* Thin Italic weight */
  font-style: italic; /* Italic style */
}

.otp-input{
  width: 48px !important;
  height: 48px  !important;
  margin: 0 4px;
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #333333;
  border-radius: 4px;
  color: black;
}

.otp-input-error{
  width: 48px !important;
  height: 48px  !important;
  margin: 0 4px;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 4px;
  color: black;
  border: 1px solid #FF0000;
}

.otp-input-success{
  width: 48px !important;
  height: 48px  !important;
  margin: 0 4px;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 4px;
  color: black;
  border: 1px solid green;
}

.otp-input:focus,
.otp-input-error:focus,
.otp-input-success:focus {
  outline: none;
  border: 1px solid #26DDE6;
}


.otp-input-filled {
  border-color: #26DDE6; /* Change to desired color */
}
