@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Default colors */
  --color-primary: 38, 221, 230;
  --color-background: 249, 249, 249;
  --color-foreground: 0, 0, 0;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: "Avenir-Next-Light";
  src: url("./../public/assets/fonts/Avenir_Next/AvenirNextLTPro-Regular.otf")
    format("truetype");
}

@font-face {
  font-family: "Avenir-Next-Medium";
  src: url("./../public/assets/fonts/Avenir_Next/AvenirNextLTPro-Demi.otf")
    format("truetype");
}

@font-face {
  font-family: "Avenir-Next-Bold";
  src: url("./../public/assets/fonts/Avenir_Next/AvenirNextLTPro-Bold.otf")
    format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
em {
  font-style: normal;
}

.font-avenir-next-light {
  font-family: "Avenir-Next-Light", sans-serif;
  font-weight: 400;
  margin: 0 !important;
  line-height: 20px;
}

.font-avenir-next-medium {
  font-family: "Avenir-Next-Medium", sans-serif;
  margin: 0 !important;
  /* line-height: 28px; */
  font-weight: 600;
}

.font-avenir-next-bold {
  font-family: "Avenir-Next-Bold", sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide {
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}
